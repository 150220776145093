import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  NavLink,
  Spinner,
  Card,
  Image,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const HomeMaster = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleNewsClick = (article) => {
    sessionStorage.setItem("article", JSON.stringify(article)); // Store in sessionStorage
    navigate("/news-detail", { state: { article } }); // Navigate to details page
  };

  const getDateRange = () => {
    const today = new Date();
    const fiveDaysAgo = new Date(today);
    fiveDaysAgo.setDate(today.getDate() - 5);
    return `${fiveDaysAgo.toISOString().split("T")[0]},${
      today.toISOString().split("T")[0]
    }`;
  };

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://api.mediastack.com/v1/news?access_key=9972afa0eb1d94f3077ab41d7f6b561e&languages=en&countries=in&categories=general&keywords=-war,-drug,-attack,-crime,-scam,-accused,-sex,-complaint,-fir,-threatens,-peace,-arab,-accident,-died,-pakistan&keywords=president,primeminister,minister,government&date=${getDateRange()}`
        );

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        let filteredArticles = data.data.filter((item) => item.image);

        // ✅ Remove duplicate articles based on 'title'
        const seenTitles = new Set();
        const uniqueArticles = filteredArticles.filter((article) => {
          const normalizedTitle = article.title.trim().toLowerCase();
          if (!seenTitles.has(normalizedTitle)) {
            seenTitles.add(normalizedTitle);
            return true;
          }
          return false;
        });

        // ✅ Sort articles by date (latest first) and keep the first 8
        uniqueArticles.sort(
          (a, b) => new Date(b.published_at) - new Date(a.published_at)
        );

        setArticles(uniqueArticles.slice(0, 24)); // Show the latest 8 unique articles
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading)
    return (
      <div className="text-center my-5">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    );

  if (error) return <div>Error: {error}</div>;

  const decodeAndTruncate = (htmlText, maxLength) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = htmlText || "No description available";
    const decodedText = txt.value;
    return decodedText.length > maxLength
      ? decodedText.substring(0, maxLength) + "..."
      : decodedText;
  };

  const isVideoUrl = (url) =>
    url && (url.endsWith(".mp4") || url.includes("youtube"));

  return (
    <Container fluid>
      <h4 className="fw-bold my-3">Global Picks</h4>
      <Row>
        {/* ✅ Highlight the first article */}
        {articles.length > 0 && (
          <Col lg={6} className="mb-4">
            <NavLink
              as={Link}
              to="/news-detail"
              onClick={() => handleNewsClick(articles[0])}
            >
              <div>
                {isVideoUrl(articles[0].image) ? (
                  <video controls className="img-fluid mb-2">
                    <source src={articles[0].image} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={articles[0].image}
                    alt={articles[0].title}
                    className="img-fluid mb-2"
                  />
                )}
                <small
                  className="text-uppercase text-primary fw-bold"
                  style={{ fontSize: "10px" }}
                >
                  {articles[0].source || "Unknown Source"}
                </small>
                <h6 className="fw-bold">
                  {decodeAndTruncate(articles[0].title, 80)}
                </h6>
                <p style={{ lineHeight: "20px" }}>
                  {decodeAndTruncate(articles[0].description, 120)}
                </p>
              </div>
            </NavLink>
          </Col>
        )}

        {/* ✅ Display next 3 articles in a row */}
        <Col lg={6}>
          {articles.slice(1, 4).map((item, index) => (
            <NavLink
              as={Link}
              to="/news-detail"
              onClick={() => handleNewsClick(item)}
              key={index}
            >
              <Row className="mb-3 align-items-start">
                <Col md={5}>
                  {isVideoUrl(item.image) ? (
                    <video controls className="img-fluid">
                      <source src={item.image} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={item.image}
                      alt={item.title}
                      className="img-fluid"
                    />
                  )}
                </Col>
                <Col md={7} className="p-0">
                  <small
                    className="text-uppercase text-primary fw-bold"
                    style={{ fontSize: "10px" }}
                  >
                    {item.source || "Unknown Source"}
                  </small>
                  <h6 className="fw-bold">
                    {decodeAndTruncate(item.title, 80)}
                  </h6>
                  <p style={{ lineHeight: "20px" }}>
                    {decodeAndTruncate(item.description, 120)}
                  </p>
                </Col>
              </Row>
            </NavLink>
          ))}
        </Col>
      </Row>
      {/* ✅ Display next 4 articles in a grid */}
      {articles.length > 4 && (
        <Row className="mt-2">
          {articles.slice(4, 8).map((item, index) => (
            <Col lg={3} md={6} sm={12} key={index} className="mb-2">
              <NavLink
                as={Link}
                to="/news-detail"
                onClick={() => handleNewsClick(item)}
              >
                <Card className="border-0 rounded-0">
                  {isVideoUrl(item.image) ? (
                    <video controls className="card-img-top rounded-0">
                      <source src={item.image} type="video/mp4" />
                    </video>
                  ) : (
                    <Card.Img
                      variant="top"
                      className="rounded-0"
                      src={item.image}
                    />
                  )}
                  <Card.Body className="p-0 pt-2">
                    <small
                      className="text-uppercase text-primary fw-bold"
                      style={{ fontSize: "10px" }}
                    >
                      {item.source || "Unknown Source"}
                    </small>
                    <h6 className="fw-bold">
                      {decodeAndTruncate(item.title, 80)}
                    </h6>
                    <p>
                      {decodeAndTruncate(
                        item.description || "No description",
                        100
                      )}
                    </p>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default HomeMaster;
